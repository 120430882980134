<template>

  <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="flat-danger"
      v-b-tooltip.hover.v-danger.bottom="$t(i18nHoverVariable)"
      class="btn-icon rounded-circle"
      @click="$emit('clicked')"
  >
    <feather-icon icon="DeleteIcon" />
  </b-button>

</template>

<script >
import Ripple from 'vue-ripple-directive'
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'DeleteButton',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    i18nHoverVariable: {
      type: String,
      default: "Delete",
    },
  },
}
</script>

<style scoped>

</style>
