<template>
  <b-button
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    variant="flat-warning"
    class="btn-icon rounded-circle"
    @click="$emit('clicked')"
  >
    <feather-icon icon="TrashIcon" />
  </b-button>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  name: 'TrashIcon',
  directives: {
    Ripple,
  },
}
</script>

<style scoped>

</style>

