import { ref } from '@vue/composition-api'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useSupervisorsAssignation(props, emit) {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { getItems, updateItem, ObjectId } = realmConnection()

  const selectedSupervisorsToAssign = ref([])
  const selectedSupervisorSameHierarchy = ref("")
  const selectedRoles = ref([])
  const selectedLocations = ref([])
  const isSubmitting = ref(false)
  const collection = 'worker'

  const getUserData = store.state?.userStore?.userData
  const client_id = getUserData.client?.$oid

  // ------------------------------------------------
  // Supervisors Assignation
  // ------------------------------------------------
  const assignSupervisors = async () => {
    isSubmitting.value = true
    
    const filterData = {
      supervisor: selectedSupervisorSameHierarchy.value,
      roles: [...selectedRoles.value],
      locations: [...selectedLocations.value],
    }
    
    try {
      const workersWithSupervisors = await getWorkersSupervisors(filterData, client_id)

      const updatePromises = workersWithSupervisors.map(worker => {
        const workerStringId = worker._id.toString()
        const originalSupervisors = worker.supervisors?.map(e => e.toString()) || []
        const filteredSupervisorsToAssign = selectedSupervisorsToAssign.value?.filter(e => e !== workerStringId) || []
        const updatedSupervisors = Array.from(new Set([...originalSupervisors, ...filteredSupervisorsToAssign]))

        return new Promise(async (resolve, reject) => {
          try {
            const query = { _id: worker._id }
            const action = { $set: { supervisors: updatedSupervisors?.map(e => ObjectId(e)) } }
            await updateItem({ collection, query, action })
            resolve(1)
          } catch (error) {
            console.log(error)
            showErrorMessage(`${i18n.t('message.err_assigning_supervisors_to')} ${worker.name}`)
            resolve(0)
          }
        })
      })

      Promise.all(updatePromises)
        .then(values => {
          const workersUpdatedCount = values.reduce((acc, element) => acc + element)
          if (workersUpdatedCount) {
            showSuccessMessage(i18n.t('message.supervisors_assigned_to_employees', 0, {count: workersUpdatedCount}))
          }
        })
        .catch(error => {
          console.log(error)
        })
    } catch (error) {
      console.log(error)
    } finally {
      // Close sidebar
      emit('update:is-supervisors-assignation-sidebar-active', false)

      isSubmitting.value = false
    }
  }

  const getWorkersSupervisors = (filterData, clientId) => {
    const conditions = {
      client_id: ObjectId(clientId),
      deleted: { $ne: true },
    }

    if (filterData.supervisor) conditions.supervisors = ObjectId(filterData.supervisor)
    if (filterData.roles.length) conditions.roles = { $in: filterData.roles.map(e => ObjectId(e)) }
    if (filterData.locations.length) conditions.locations = { $in: filterData.locations.map(e => ObjectId(e)) }

    return new Promise(async (resolve, reject) => {
      try {
        const items = await getItems({ collection, query: conditions, options: { projection : { name: 1, supervisors: 1 } } })

        if (!items?.length) {
          showErrorMessage(i18n.t('message.err_no_employees_found'))
          reject("Employees not found")
        }

        resolve(items)
      } catch (error) {
        showErrorMessage(i18n.t('message.err_worker_list'));
        reject(error)
      }
    })
  }

  return {
    assignSupervisors,
    selectedSupervisorsToAssign,
    selectedSupervisorSameHierarchy,
    selectedRoles,
    selectedLocations,
    isSubmitting,
  }
}
