import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { updateWorker, _getWorkers, getWorkersWithSupervisors } from '@/@core/queries/workers'
import { checkIfWorkerExistsInUser, updateUser } from '@/@core/queries/user'
import i18n from '@/libs/i18n'
import useCommon from '@/views/organization/useCommon'
import realmConnection from '@/views/habit/realm'

export default function useWorkerList() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { handleError } = useCommon()
  const { invokeFunction, updateItem, getItems, ObjectId } = realmConnection()
  const { role, worker_id = null, client = null } = store.state?.userStore?.userData
  const workers = ref([])
  const collection = 'worker'

  // Table Headers
  const tableColumns = computed(() => {
    return [
      { key: 'name', label: i18n.t('message.tableHeader.name'), sortable: true },
      { key: 'email', label: i18n.t('message.tableHeader.email'), sortable: true },
      { key: 'Actions', label: i18n.t('message.tableHeader.actions') },
    ]
  })
  const perPage = ref(10)
  const totalWorkers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 15, 20, 25, 30, 40, 50, 100]
  const searchQuery = ref('')
  const locationQuery = ref('')
  const locations = ref([])
  const defaultSortBy = 'name'
  const sortBy = ref(defaultSortBy)
  const isSortDirDesc = ref(false)
  const isLoading = ref(true)
  const locationFilter = ref([])
  const roleFilter = ref([])
  const workersTotal = ref([])

  const excelFields = ref({
    [i18n.t('message.tableHeader.name')]: "name",
    [i18n.t('Email')]: "email",
    [i18n.t('label.Locations')]: "locationsData",
    [i18n.t('label.Privileges')]: "privileges",
    [i18n.t('label.Roles')]: "rolesData",
    [i18n.t('message.tableHeader.supervisor')]: "supervisorsData",
  })

  const fetchWorkers = async () => {
    isLoading.value = true

    try {
      const input = {
        limit: perPage.value,
        total: currentPage.value === 1,
        offset: (currentPage.value * perPage.value) - perPage.value,
        query: {},
        search: searchQuery.value,
        sortBy: sortBy.value || defaultSortBy,
        sortOrder: isSortDirDesc.value ? -1 : 1,
        location: locationFilter.value,
        roles: roleFilter.value,
        client_id: role !== 'admin' ? client.$oid : null,
        supervisor: role === 'supervisor' ? worker_id.$oid : null
      }
    
      const items = await invokeFunction({ name: 'WorkersQueryWithOffset', arg: input })

      workers.value = items?.workers || []
      if (currentPage.value === 1) totalWorkers.value = items?.totalRecords || 0

      const modifiedWorkers = items?.workersTotal?.map(worker => (
        {
          ...worker,
          locationsData: worker?.locationsData?.map(location => location.location).join(" / ") || "",
          rolesData: worker?.rolesData?.map(role => role.name).join(" / ") || "",
          supervisorsData: worker?.supervisorsData?.map(supervisor => supervisor.name).join(" / ") || ""
        }
      ))
      workersTotal.value = modifiedWorkers
      
    } catch (error) {
      console.log(error)
      handleError({ error, defaultMessage: i18n.t('message.err_worker_list') })
    } finally {
      isLoading.value = false
    }
  }

  const deleteWorker = async (id) => {
    try {
      const query = { _id: id }
      const action = { $set: { deleted: true, deletedBy: ObjectId(worker_id.$oid), deletedAt: new Date() } }

      await updateItem({ collection, query, action })

      // Check if worker is an existing user and soft delete it
      const userQuery = { 'custom_data.worker_id': id }
      await updateItem({ collection: 'user', query: userQuery, action })

      showSuccessMessage(i18n.t('message.worker_remove'))
      fetchWorkers()

      // Check if worker supervises other workers and remove it from the list of those workers' supervisors
      const supervisorQuery = { supervisors: id }
      const items = await getItems({ collection, query: supervisorQuery })

      const deletedWorkerStringId = id.toString()

      items?.forEach(worker => {
        if (worker?.supervisors?.length) {
          const supervisorsFiltered = worker.supervisors.filter(e => e.toString() !== deletedWorkerStringId)

          try {
            updateItem({ collection: 'worker', query: { _id: worker._id }, action: { $set: { supervisors: supervisorsFiltered } } })
          } catch (error) {
            console.log(error)
          }
        }
      })
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.something_went_wrong'))
    }
  }

  const updateFilter = (data) => {
    locationFilter.value = data.locationFilter
    roleFilter.value = data.roleFilter

    fetchWorkers()
  }

  watch([currentPage, perPage], () => {
    fetchWorkers()
  })

  watch([locationQuery, searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    fetchWorkers()
  })

  return {
    fetchWorkers,
    tableColumns,
    perPage,
    currentPage,
    totalWorkers,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    workers,
    deleteWorker,
    locationQuery,
    locations,
    locationFilter,
    roleFilter,
    isLoading,
    role,
    excelFields,
    workersTotal,
    updateFilter
  }
}
