export const queryUsers = `query users($query: UserQueryInput, $limit: Int, $sortBy: UserSortByInput){
    users(query: $query, limit: $limit, sortBy: $sortBy) {
      _id
      custom_data {
        project
        role
        username
      }
      data {
        email
      }
      id
    }
  }
  `
export const queryUser = `query user($query: UserQueryInput){
    user(query: $query) {
      _id
      custom_data {
        project
        role
        username
      }
      data {
        email
      }
      id
    }
  }
  `

export const addUser = `mutation insertOneUser($data: UserInsertInput!){
    insertOneUser(data: $data) {
      _id
      id
      custom_data {
        project
        role
        username
      }
      data {
        email
      }
    }
  }`

export const updateUser = `mutation updateOneUser($query: UserQueryInput!, $data: UserUpdateInput!) {
  updateOneUser(query: $query, set: $data) {
    _id
  }
}
`

export const deleteOneUser = `mutation deleteOneUser($query: UserQueryInput!) {
  deleteOneUser(query: $query) {
    _id
  }
}
`
export const checkIfWorkerExistsInUser = `query getWorkerId($query: UserQueryInput!) {
  user(query: $query) {
    _id
    custom_data {
      worker_id
      role
    }
    id
  }
}`
