<template>
  <div>
    <b-sidebar
      id="sidebar-supervisors-assignation"
      sidebar-class="sidebar-lg"
      :visible="isSupervisorsAssignationSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-supervisors-assignation-sidebar-active', val)"
      @hidden="resetForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">{{ $t('assignSupervisors') }}</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="assignSupervisorsForm">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <!-- Supervisors to assign -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.supervisorsToAssign')"
              rules="required"
            >
              <b-form-group :label="$t('label.supervisorsToAssign')" label-for="supervisorsToAssign">
                <v-select
                  v-model="selectedSupervisorsToAssign"
                  :reduce="(supervisors) => supervisors.value"
                  :multiple="true"
                  label="title"
                  :options="supervisors"
                  :placeholder="$t('placeholder.supervisorsToAssign')"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <hr>

            <!-- Supervisor with same hierarchy -->
            <b-form-group :label="$t('label.supervisorSameHierarchy')" label-for="supervisorSameHierarchy">
              <v-select
                v-model="selectedSupervisorSameHierarchy"
                :reduce="(supervisors) => supervisors.value"
                label="title"
                :options="supervisors"
                :placeholder="$t('placeholder.supervisorSameHierarchy')"
              />
            </b-form-group>

            <!-- Roles -->
            <b-form-group :label="$t('label.Roles')" label-for="roles">
              <v-select
                v-model="selectedRoles"
                :reduce="(roles) => roles.value"
                :multiple="true"
                label="title"
                :options="roles"
                :placeholder="$t('placeholder.roles')"
              />
            </b-form-group>

            <!-- Locations -->
            <b-form-group :label="$t('label.Locations')" label-for="locations">
              <v-select
                v-model="selectedLocations"
                :reduce="(locations) => locations.value"
                :multiple="true"
                label="title"
                :options="locations"
                :placeholder="$t('placeholder.locations')"
              />
            </b-form-group>
            
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                class="mr-2"
                :disabled="isSubmitting"
                @click="validateAndOpenModal()"
              >
                {{ $t('assignSupervisors') }}
              </b-button>
              <b-modal
                id="modal-assign-supervisors"
                ref="assignSupervisorsModal"
                centered
                no-close-on-backdrop
                modal-class="modal-info"
                ok-variant="info"
                cancel-variant="outline-secondary"
                :title="$t('message.confirm_action')"
                :ok-title="$t('message.assign')"
                :cancel-title="$t('message.cancel')"
                @ok="assignSupervisors"
              >
                {{ $t('message.confirm_supervisors_assignation') }}
              </b-modal>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BButton, BFormInvalidFeedback, BModal, VBModal } from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ref, toRefs, onMounted } from "@vue/composition-api";
import useSupervisorsAssignation from "./useSupervisorsAssignation";
import useCommon from "@/views/organization/useCommon";
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    vSelect,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    BModal,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  model: {
    prop: "isSupervisorsAssignationSidebarActive",
    event: "update:is-supervisors-assignation-sidebar-active",
  },
  props: {
    isSupervisorsAssignationSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const { getSupervisorsForDropDown, supervisors, getRolesForDropDown, roles, getLocationsForDropDown, locations } = useCommon()

    onMounted(async () => {
      getSupervisorsForDropDown();
      getRolesForDropDown();
      getLocationsForDropDown();
    })

    const { assignSupervisors, selectedSupervisorsToAssign, selectedSupervisorSameHierarchy, selectedRoles, selectedLocations, isSubmitting } = useSupervisorsAssignation(toRefs(props), emit)

    const { getValidationState } = formValidation()
    const { showErrorMessage } = useNotifications()

    const assignSupervisorsForm = ref(null)
    const assignSupervisorsModal = ref(null)

    const validateForm = () => new Promise((resolve, reject) => {
      assignSupervisorsForm.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validateAndOpenModal = () => {
      validateForm()
        .then(() => {
          if (!selectedSupervisorSameHierarchy.value && !selectedRoles.value.length && !selectedLocations.value.length) {
            showErrorMessage(i18n.t('message.mustSelectOneFilterField'))
            return
          }
          assignSupervisorsModal.value.show()
        })
        .catch(() => showErrorMessage(i18n.t('message.requiredFieldsIncomplete')))
    }

    const resetForm = () => {
      selectedSupervisorsToAssign.value = []
      selectedSupervisorSameHierarchy.value = ""
      selectedRoles.value = []
      selectedLocations.value = []
      assignSupervisorsForm.value.reset()
    }

    return {
      getValidationState,
      supervisors,
      roles,
      locations,
      assignSupervisors,
      isSubmitting,
      selectedSupervisorsToAssign,
      selectedSupervisorSameHierarchy,
      selectedRoles,
      selectedLocations,
      validateAndOpenModal,
      assignSupervisorsForm,
      assignSupervisorsModal,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>